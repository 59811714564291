import React, { useState, useEffect } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement, ChartOptions } from 'chart.js';
import { ArrowDownCircle, ArrowRight, ArrowUpCircle, PiggyBank, UserCheck, Users, Wallet } from 'lucide-react';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

type User = {
  id: string;
  name: string;
  email: string;
  number: string;
  total_deposit_usdt: number;
  total_withdrawal_usdt: number;
};

interface DepositTransaction {
  uid: number | string;
  date: string;
  txtid: string;
  amount: number;
  coin: string;
}

interface LiveBet {
  game: string;
  uid: number;
  amount: number;
  coin: string;
  name: string;
}

interface WithdrawalTransaction {
  uid: number | string;
  date: string;
  wallet: string;
  amount: number;
  coin: string;
  name: string | null;
}

const AdminDashboard = () => {
  const [userCount, setUserCount] = useState(0);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);
  const [users, setUsers] = useState<User[]>([]);
  const [todayDeposits, setTodayDeposits] = useState(0);
  const [todayWithdrawals, setTodayWithdrawals] = useState(0);
  const [liveBets, setLiveBets] = useState<LiveBet[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [transactions, setTransactions] = useState<Array<DepositTransaction | WithdrawalTransaction>>([]);

  const [chartData1, setChartData] = useState({
    labels: [],
    datasets: [{
      data: [],
      backgroundColor: 'indigo',
    }]
  });

  const [pieData, setPieData] = useState({
    labels: ['Crash', 'Mines', 'Other Games'],
    datasets: [{
      data: [0, 0, 0],
      backgroundColor: ['#4338ca', '#818cf8', '#c7d2fe'],
    }]
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userResponse = await fetch('https://api.bitrubix.games/users');
        const userData = await userResponse.json();
        setUsers(userData);
        setUserCount(userData.length);

        const totalDepositsResponse = await fetch('https://api.bitrubix.games/total-deposits');
        const totalDepositsData = await totalDepositsResponse.json();
        setTotalDeposits(totalDepositsData.total_deposits_usdt);

        const totalWithdrawalsResponse = await fetch('https://api.bitrubix.games/total-withdrawals');
        const totalWithdrawalsData = await totalWithdrawalsResponse.json();
        setTotalWithdrawals(totalWithdrawalsData.total_withdrawals_usdt);

      } catch (error) {
        console.error('Failed to fetch user data:', error);
      }
    };

    const fetchTodayDepositsAndWithdrawals = async () => {
      try {
        const today = new Date().toISOString().split('T')[0];

        const depositResponse = await fetch('https://api.bitrubix.games/today-deposits');
        const depositData = await depositResponse.json();

        const totalDepositAmountToday = depositData.reduce((total: any, deposit: { date: string; amount: any; }) => {
          const depositDate = deposit.date.split('T')[0];
          return depositDate === today ? total + deposit.amount : total;
        }, 0);
        setTodayDeposits(totalDepositAmountToday);

        const withdrawResponse = await fetch('https://api.bitrubix.games/today-withdrawals');
        const withdrawData = await withdrawResponse.json();

        const totalWithdrawalAmountToday = withdrawData.reduce((total: any, withdraw: { date: string; amount: any; }) => {
          const withdrawDate = withdraw.date.split('T')[0];
          return withdrawDate === today ? total + withdraw.amount : total;
        }, 0);
        setTodayWithdrawals(totalWithdrawalAmountToday);

      } catch (error) {
        console.error('Failed to fetch deposit or withdrawal data:', error);
      }
    };

    fetchTodayDepositsAndWithdrawals();
    fetchUserData();
  }, []);

  const fetchTodayDeposits = async () => {
    const response = await fetch('https://api.bitrubix.games/today-deposits');
    const data: DepositTransaction[] = await response.json();
    const today = new Date().toISOString().split('T')[0];
    const todayDeposits = data.filter(deposit => new Date(deposit.date).toISOString().split('T')[0] === today);
    setTransactions(todayDeposits);
  };

  const fetchTodayWithdrawals = async () => {
    const response = await fetch('https://api.bitrubix.games/today-withdrawals');
    const data: WithdrawalTransaction[] = await response.json();
    const today = new Date().toISOString().split('T')[0];
    const todayWithdrawals = data.filter(withdrawal => new Date(withdrawal.date).toISOString().split('T')[0] === today);
    setTransactions(todayWithdrawals);
  };

  const handleShowTransactions = async (type: 'deposit' | 'withdrawal') => {
    setShowModal(true);
    if (type === 'deposit') {
      await fetchTodayDeposits();
    } else {
      await fetchTodayWithdrawals();
    }
  };

  const fetchLiveBets = async () => {
    try {
      const response = await fetch('https://api.bitrubix.games/live-bets');
      const data: LiveBet[] = await response.json();
      setLiveBets(data);
    } catch (error) {
      console.error('Failed to fetch live bets:', error);
    }
  };

  useEffect(() => {
    fetchLiveBets();
    const interval = setInterval(fetchLiveBets, 10000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Update chart data when liveBets changes

    // setChartData({
    //   labels: Object.keys(gameData),
    //   datasets: [{
    //     data: Object.values(gameData),
    //     backgroundColor: 'indigo',
    //   }]
    // });

    // Update pie chart data
    const crashBets = liveBets.filter(bet => bet.game === 'crash').reduce((sum, bet) => sum + bet.amount, 0);
    const mineBets = liveBets.filter(bet => bet.game === 'mine').reduce((sum, bet) => sum + bet.amount, 0);
    const otherBets = liveBets.filter(bet => bet.game !== 'crash' && bet.game !== 'mine').reduce((sum, bet) => sum + bet.amount, 0);

    setPieData({
      labels: ['Crash', 'Mines', 'Other Games'],
      datasets: [{
        data: [crashBets, mineBets, otherBets],
        backgroundColor: ['#4338ca', '#818cf8', '#c7d2fe'],
      }]
    });
  }, [liveBets]);


  const gameData = {
    Mine: 400000,
    Crash: 600000,
    Plinko: 1480000,
    High_Low: 700000,
    Hash_Dice: 100000,
    SingleKeno: 20000,
    Hilo: 10000
  };

  const chartData = {
    labels: Object.keys(gameData),
    datasets: [{
      data: Object.values(gameData),
      backgroundColor: '#646ECD', // Indigo color
      barThickness: 40,
    }]
  };

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        // text: 'Live Bets by Game',
        font: {
          size: 18,
          weight: 'bold' as const,

        },
        padding: {
          top: 10,
          bottom: 30,
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',  // This line changes the x-axis label color to white
        },
        border: {
          color: 'white'  // This changes the x-axis line color to white
        }
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: 'white',  // This line changes the text color to white
          callback: function (value) {
            return (value as number).toLocaleString();
          },
          stepSize: 200000,
          // max: 1600000,
        },
        border: {
          color: 'white'  // This changes the x-axis line color to white
        }
      },
    },
  };
  return (
    <div className='ml-0 lg:ml-[256px] h-screen '>
      <h1 className="text-3xl font-bold mb-8 text-white">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <Users className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-green-400 px-2 py-1 rounded flex gap-1 items-center">+12% <ArrowUpCircle size={15}/></span>
      
          </div>
          <p className="text-white text-2xl font-bold mt-2">{userCount}</p>
          <p className="text-white text-sm mt-1">Total Users</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>

        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <PiggyBank className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-green-400 px-2 py-1 rounded flex gap-1 items-center">+12% <ArrowUpCircle size={15}/></span>
          </div>
          <p className="text-white text-2xl font-bold mt-2">${totalDeposits.toFixed(2)}</p>
          <p className="text-white text-sm mt-1">Total Deposits</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>

        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <Wallet className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-red-400 px-2 py-1 rounded flex gap-1 items-center">-28% <ArrowDownCircle size={15}/></span>
          </div>
          <p className="text-white text-2xl font-bold mt-2">${totalWithdrawals.toFixed(2)}</p>
          <p className="text-white text-sm mt-1">Total Withdrawals</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>

        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <UserCheck className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-green-400 px-2 py-1 rounded flex gap-1 items-center">+84% <ArrowUpCircle size={15}/></span>
          </div>
          <p className="text-white text-2xl font-bold mt-2">{liveBets.length}</p>
          <p className="text-white text-sm mt-1">Live Users</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>

        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <ArrowUpCircle className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-green-400 px-2 py-1 rounded flex gap-1 items-center">+0% <ArrowUpCircle size={15}/></span>
          </div>
          <p className="text-white text-2xl font-bold mt-2">${todayDeposits.toFixed(2)}</p>
          <p className="text-white text-sm mt-1">Today's Deposit</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>

        <div className="bg-[#646ECD] p-4 rounded-lg shadow-md">
          <div className="flex items-center justify-between">
            <ArrowDownCircle className="text-white h-6 w-6" />
            <span className="text-white text-xs font-semibold bg-green-400 px-2 py-1 rounded flex gap-1 items-center">+0% <ArrowUpCircle size={15}/></span>
          </div>
          <p className="text-white text-2xl font-bold mt-2">${todayWithdrawals.toFixed(2)}</p>
          <p className="text-white text-sm mt-1">Today's Withdrawal</p>
          <div className="mt-2 text-white text-xs flex items-center">
            <span>Since last month</span>
            <ArrowRight className="h-4 w-4 ml-1" />
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
        <div className="bg-[#37384A] p-4 rounded-lg shadow-md">
          <h2 className="text-xl text-white font-semibold mb-4">Live Bets by Game</h2>
          <div className="bg-[#37384A] p-6 rounded-lg ">
            <div style={{ height: '400px' }}>
              <Bar data={chartData} options={options} />
            </div>
          </div>
        </div>
        <div className="bg-[#37384A]  p-4 rounded-lg shadow-md">
          <h2 className="text-xl text-white font-semibold mb-4">Bet Distribution</h2>
          <Pie
            data={pieData}
            options={{
              responsive: true,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: 'white'  // This changes the legend text color to white
                  }
                }
              },
              color: 'white'  // This changes the overall chart text color to white
            }}
          />
        </div>
      </div>
      <div className='flex justify-center items-start h-screen'>
        <div className='w-full h-[450px] overflow-auto relative rounded-lg'>
          <table className="text-sm text-left text-gray-500 w-full">
            <thead className="text-m text-white uppercase bg-[#646ECD] sticky top-0 z-5">
              <tr>
                <th scope="col" className="py-3 px-6">Live Bets</th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
                <th scope="col" className="py-3 px-6"></th>
              </tr>
            </thead>
            <thead className="text-xs text-white uppercase bg-[#37384A]   top-[3rem] z-10">
              <tr>
                <th scope="col" className="py-3 px-6">S.No</th>
                <th scope="col" className="py-3 px-6">Name</th>
                <th scope="col" className="py-3 px-6">Uid</th>
                <th scope="col" className="py-3 px-6">Game</th>
                <th scope="col" className="py-3 px-6">Bet</th>
                <th scope="col" className="py-3 px-6">Coin</th>
              </tr>
            </thead>
            <tbody className=''>
              {liveBets.reverse().map((bet: { name: any; uid: any; game: any; amount: { toString: () => string; }; coin: string; }, index: number) => (
                <tr key={index} className="group bg-[#37384A] border-b border-gray-200 hover:bg-gray-50">
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{index + 1}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{bet.name}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{bet.uid}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{bet.game}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">${parseFloat(bet.amount.toString()).toFixed(8)}</td>
                  <td className="text-white group-hover:text-gray-600 py-4 px-6">{bet.coin.toUpperCase()}</td>
                </tr>
              ))}
            </tbody>

          </table>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
