// Settings.js
const Settings = () => {
	return (
		<div className='ml-0 lg:ml-[256px]'>
			<h1 className='text-2xl font-bold'>Settings Page</h1>
			{/* Settings page content */}
		</div>
	);
};

export default Settings;
